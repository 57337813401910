'use strict';

angular.module('kljDigitalLibraryApp')
    .service('JournalService', ["$q", "common", "appConfig","$rootScope", function($q, common, appConfig,$rootScope) {


        this.totalJournals = [];
        var that = this;
        var journalBaseUrl = '/api/case_journals';
        that.stopCallingApi = false;

        this.getAll = function(body, appendRecords) {
            console.log("inside getAll9()", body);

            return common.callApi('POST',
                journalBaseUrl + '/journalSearch',
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE,
                body
            )

            .then(function(journals) {
                console.log("journals>>>>>>>>>>>> ",journals);
                if(!$rootScope.offlineApp){
                    if (journals && journals.data.list && journals.data.list.length > 0) {
                        that.stopCallingApi = false;
                    } else {
                        that.stopCallingApi = true;
                    }

                    if (undefined === appendRecords || false === appendRecords || '' === appendRecords) {
                        that.totalJournals = [];
                        that.totalJournals = that.totalJournals.concat(journals.data.list);
                    } else {
                        that.totalJournals = that.totalJournals.concat(journals.data.list);
                    }
                    return {journals:that.totalJournals,totalCount:journals.data.count,status:journals.data.status};
                }
                else{


                    if (journals && journals.data && journals.data.length > 0) {
                        that.stopCallingApi = false;
                    } else {
                        that.stopCallingApi = true;
                    }

                    if (undefined === appendRecords || false === appendRecords || '' === appendRecords) {
                        that.totalJournals = [];
                        that.totalJournals = that.totalJournals.concat(journals.data);
                    } else {
                        that.totalJournals = that.totalJournals.concat(journals.data);
                    }
                    return that.totalJournals;

                }
                    
                })
                .catch(function(err) {
                    console.log("journals err>>>>>>>>> ",err);
                    // return{status:err.data.status,message:err.data.message};
                    return err.data;
                   
                });
        }

        this.getDetails = function(id, body) {



            return common.callApi('POST',
                journalBaseUrl + '/getCaseJournal/' + id,
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE,
                body
            )

            .then(function(response) {

                return response.data;
            })
        }

        this.openNote = function(body) {
            var that = this;
            return common.callApi('POST',
                journalBaseUrl + '/journalSearch',
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE,
                body
            )

            .then(function(response) {
                that.stopCallingApi = true;
                return response.data
            })
            .catch(function(err){
                that.stopCallingApi = true;
                console.log(err,"====error==");
            })
        }



    }]);